import React, { useEffect, useState } from 'react';

import { navigate } from 'gatsby';
import LocalizedStrings from 'react-localization';
import { StringParam, useQueryParam } from 'use-query-params';

import { Events, Layout } from '../components';
import Operators from '../components/UserList';
import { apiRequester, handleError } from '../utility';

const strings = new LocalizedStrings({
    en: {
        events: 'Events',
        selectEvent: 'Select an event form the list below',
        invalidClient: 'Invalid client ID',
    },
    de: {
        events: 'Events',
        selectEvent: 'Wählen Sie ein Event aus der unten stehenden Liste aus',
        invalidClient: 'Ungültige Kunden-ID',
    },
});

const ClientPage = () => {
    const [clientId, setClientId] = useQueryParam('clientId', StringParam);
    const [client, setClient] = useState<Clients.Client>();
    const [activeTab, setActiveTab] = useState('1');

    const getClient = async (clientId: string) => {
        try {
            const client = await apiRequester.getClient(clientId);
            if (client) setClient(client);
            else throw new Error(strings.invalidClient);
            return client;
        } catch (err) {
            handleError(err);
        }
    };

    useEffect(() => {
        if (!clientId) navigate('/');
        else {
            apiRequester
                .getEvents(clientId)
                .then(async () => await getClient(clientId))
                .catch(handleError);
        }
    }, []);

    return (
        <Layout title={strings.events} subTitle={strings.selectEvent}>
            {activeTab === '1' ? <Events clientId={clientId} /> : <Operators clientId={clientId} />}
        </Layout>
    );
};

export default ClientPage;
